import { useContext } from 'react';
import { Redirect } from 'react-router';
import { Container, Row, Col, Button } from 'reactstrap';
import { AuthenticationContext } from '../msal/AuthenticationContext';
import { TenantContext } from '../context/TenantContext';
import { AspectRatio } from '../components/AspectRatio';
import { urls } from '../logic/urls';

import css from './Home.module.scss';
import sharedCss from './Shared.module.scss';

export const Home = () => {
    const authenticationInfo = useContext(AuthenticationContext);
    const tenantContext = useContext(TenantContext);

    if (authenticationInfo.isAuthenticated() && authenticationInfo.isAuthorized()) {
        if (!tenantContext.isLoading && tenantContext.isDistributor) {
            if (tenantContext.isReseller) {
                return <Redirect to={urls.reseller}/>
            } else {
                return <Redirect to={urls.disributor}/>
            }
        }

        return <Redirect to={urls.license}/>
    }

    return (
        <div className={sharedCss.background}>
            <Container fluid={true}>
                <div className="d-flex">
                    <div className="p-4">
                        <img className={sharedCss.logo} src="./images/cybertwice_logo_v2_notext.svg" alt="Cybertwice logo"/>
                    </div>                    
                </div>

                <div className="bg-dark d-flex flex-row-reverse p-3">
                    <Button color='primary' onClick={authenticationInfo.login}>Login</Button>                        
                </div>
            
                <AspectRatio className={css.topImageContainer} aspectRatio="1:0.21">
                    <img src="./images/calling-women.jpg" alt="" />                
                    <div className={css.overlayHeader}>
                        <p>CyberGate Management Portal</p>
                    </div>
                </AspectRatio>   
            </Container>
            <Container>
                <div className="text-center mt-5">
                    <h2>The cloud based SIP-to-Teams adapter</h2>                
                </div>  
                <div className={css.centerImageContainer}>
                    <img src="./images/cybergate-overview-v2.png" alt="Teams Sip gateway" />
                </div>                
                <div>
                    <p>Revision 1.100</p>
                </div>
            </Container>
            <Container fluid={true} className="bg-dark">
                <Container className={`text-white ${css.footerList}`}>
                    <Row>
                        <Col className="text-center">
                            <ul>
                                <li><h5>Contact information</h5></li>
                                <li>+31 72 210 02 04</li>
                                <li>info@cybertwice.com</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <p>
                                <span className="ml-1">© 2024 <a href="https://cybertwice.com" target="_blank" rel="noopener noreferrer">CyberTwice</a></span>
                                <span> |
                                        <a href="https://cybertwice.com/disclaimer.html" target="_blank" rel="noopener noreferrer"><span className="ml-1">Disclaimer</span></a>
                                </span>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    );
};


